import { API_RESOLVER_QUERIES, EventMessage, FeatureCore, MESSAGE_STORAGE_API_NAME, QueryMessage } from '@dreamcommerce/star_core';
import { PAGE_MANAGER_EVENTS } from '@storefrontRoot/core_features/page_management/turbo_message_names';
import { PAGE_MANAGER_LOADED_HANDLER_NAME } from '@storefrontCoreFeatures/page_management/events/page_manager_events_constants';
import { IMessageStorageApi } from '@dreamcommerce/star_core/build/esm/packages/star_core/src/features/messaging/storage/message_storage_types';

export class PageManagerLoadedHandler extends FeatureCore {
    public readonly moduleName = PAGE_MANAGER_LOADED_HANDLER_NAME;

    #messageStorageApi: IMessageStorageApi;
    readonly #callback: (event: EventMessage<CustomEvent>) => void;

    constructor(callback: (event: EventMessage<CustomEvent>) => void) {
        super();

        this.#messageStorageApi = this.queryBus.executeSync<QueryMessage<string>, IMessageStorageApi>(
            new QueryMessage(API_RESOLVER_QUERIES.getApiSync, MESSAGE_STORAGE_API_NAME)
        )!;

        this.#callback = callback;
        this._handlePastPageLoads();
        this.eventBus.on(PAGE_MANAGER_EVENTS.loaded, callback);
    }

    private _handlePastPageLoads(): void {
        const pastPageLoads = this.#messageStorageApi.getChannelMessages(PAGE_MANAGER_EVENTS.loaded) as EventMessage<CustomEvent>[];

        if (pastPageLoads.length > 0) {
            pastPageLoads.forEach((event) => this.#callback(event));
        }
    }
}
